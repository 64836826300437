import React from 'react';
import Utils from '../../../js/utils.js';
import ColorLegend from './colorLegend.jsx';
import AsOut from "./asOut.jsx";

const GraphFooter = (props) => {
    const getStats = () => {
        let stats = [];
        if (Utils.isSet(props.graphData)) {
            var data = props.graphData.latency;
            if (Utils.isSet(data)){
                stats.push(
                    <span key="0" className="span-info latency-max"> Max: <span className="val">{ data.max } ms</span> </span>
                )
                stats.push(
                    <span key="1" className="span-info latency-min"> Min: <span className="val">{ data.min } ms</span> </span>
                )
            }
        }
        if (Utils.isSet(props.graphData)) {
            var data = props.graphData.packets;
            if (Utils.isSet(data)) {
                let losInPerc = (data.total > 0 && data.total_loss > 0)?Number((data.total_loss / (data.total / 100))): 0 ;
                losInPerc = losInPerc.toFixed(2);
                let colorLoss = Utils.getLossColor(losInPerc);
                let style = {'color': colorLoss};
                if(props.showInfo){
                    stats.push(
                        <span key="2" className="span-info packet-total"> Count:  <span className="val">{ Utils.formatNumber(data.total,0)}</span> </span>
                    )
                }
                stats.push(
                    <span key="3" className="span-info packet-total"> Loss:  <span className="val">{ Utils.formatNumber(data.total_loss,0) }</span> </span>
                )
                stats.push(
                    <span key="4" className="span-info packet-total"> <span className="val" style={style}>{losInPerc + '%'}</span></span>
                )
            }
        }
        return (
            <div className="footer-block stat-block">
                {stats}
            </div>
        );

    };
    const getColorLegend = () => {
        if(props.isMobile){return;}
        return (
            <div className="footer-block legend-block">
                <ColorLegend
                    graphData={props.graphData}
                    stats={props.stats}
                    pxWidth={props.pxWidth}
                    chart={props.chart}
                    graphType={props.ipDetails.graphType}
                    accuracy={props.ipDetails.accuracy}
                    isIframe={props.isIframe}
                />
            </div>
        )
    };
    return (
        <div className="footer">
            {props.showInfo && getColorLegend()}
            {getStats()}
            {window.location.hash.includes('integration') && <AsOut ip={props.selectedIP} probe={props.ipDetails.probe}/>}
        </div>
    );
}

export default GraphFooter;
