import React from 'react';
import Header from './header/head/header.jsx'
import InnerWrap from './header/inner/innerWrap.jsx'
import Graph from './graph.jsx';
import GraphFooter from './footer/footer.jsx';
import TraceLegend from './footer/traceLegend.jsx';

import Utils from '../../js/utils';
import '../../js/dataBasic';
import '../../js/dataAdvanced';

class GraphContainer extends React.Component{

    constructor(props){
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        chart: null,
        graphData: null,
        yAxisLimit: null,
        stats: {},
        isUTC: false,
        zoomLevel: 1,
        customDebugAgrCount: 0,
    });

    setUTC = () => this.setState((prev) => ({ isUTC: !prev.isUTC }))

    setChartInstance = (key, data) => this.setState({ [key]: data })

    loadGraph = (data, ip, now, graphType) => {
        if (graphType == undefined) {
            graphType = this.props.ipDetails.graphType;
        }
        if (now == undefined) {
            now = Utils.getTimeNow();
        }
        if (graphType == 'basic') {
            this.loadBasicGraph(data, ip, now);
        } else {
            this.loadAdvancedGraph(data, ip, now);
        }
    }

    switchYAxis = () => {
        let chart = this.state.chart;
        let temp = chart.yAxis[0].max;
        let max = this.state.yAxisLimit;
        chart.yAxis[0].update({ max: max });
        this.setState({ yAxisLimit: temp });
    }

    getAgrCount = (epoch, type) => {
        const level = this.props.ipDetails.accuracy === 'normal' ? 0 : 1; // [low - 0, high - 1]
        const px = Utils.getChartLineWidth(this.props.isMobile, this.props.isNarrow);
        const isBasic = type === 'basic';
        // -----------  calculate aggregation --------------
        const basicThreshold = { min: 5, normal: px * 0.5, high: px * 2 };
        const advancedThreshold = { min: 30, normal: px * 0.1, high: px * 0.5 };
        const threshold = isBasic ? basicThreshold : advancedThreshold;
        //--------
        const valueCount = level ? threshold.high : threshold.normal;
        let agrCount = epoch / valueCount;
        agrCount = agrCount < threshold.min ? threshold.min : agrCount;
        // -------------------------------------------------
        const { customDebugAgrCount } = this.state;
        if (customDebugAgrCount > 0) {
            return customDebugAgrCount;
        }
        // AGRCOUNT - represents number of seconds to aggregate together
        return Math.ceil(agrCount);
    }

    loadBasicGraph = (data, ip, now) => {
        const { epoch } = data.ipDetails;
        const count = this.getAgrCount(epoch, 'basic');
        const { probe } = data.ipDetails;
        const callback = this.setGraphData;
        DataBasic.loadData(ip, now, epoch, count, probe, callback);
    }

    loadAdvancedGraph = (data, ip, now) => {
        const { epoch } = data.ipDetails;
        const count = this.getAgrCount(epoch, 'advanced');
        const { probe } = data.ipDetails;
        const callback = this.setGraphData;
        DataAdvanced.loadData(ip, now, epoch, count, probe, callback);
    }

    setGraphData = (dataO, data, timestamp, stats) => {
        const componentProbe = Utils.getPropertyOfObject(['ipDetails', 'probe'], this.props);
        const componentIP = Utils.getPropertyOfObject(['selectedIP'], this.props);
        const dataProbe = Utils.getPropertyOfObject(['probe'], stats);
        const dataIP = Utils.getPropertyOfObject(['ip'], stats);
        // local probe or IP might have change before the callback returned
        if (componentProbe === dataProbe && componentIP === dataIP) {
            let graphDataNew = data;
            if (this.props.ipDetails.graphType === 'advanced') {
                graphDataNew = DataAdvanced.getDisplayData(data, this.props.theme.shadowReverse);
            }
            this.setState({
                originalData: dataO,
                graphData: graphDataNew,
                stats,
            });
        }
    }

    getID = () => {
        if (this.props.ipDetails.graphType == 'advanced') {
            return 'advanced-graph-wrap';
        } else {
            return 'basic-graph-wrap';
        }
    }

    getDataInfo = () => {
        let data = this.state.graphData;
        let ip = "",alias = "";
        if(Utils.isSet(data) && Utils.isSet(data.info)){
            ip = data.info.ip;
            alias = data.info['ip-desc'];
        }
        if(!alias.includes(ip)){
            alias += ' - ' + ip;
        }
        return {
            'ip': ip,
            'alias': alias
        }
    }

    getTrueTimeFrame = () => {
        let data = this.state.graphData;
        let ipData = this.props.ipDetails;
        if (Utils.isSet(data) && Utils.isSet(data.epoch)){
            return {'to':ipData.endPoint , 'epoch':data.epoch}
        }
        return {'to':ipData.endPoint , 'epoch':ipData.epoch};
    }

    setEpochFromZoom = (from, to, res = true) => {
        const epoch = to - from;
        this.props.setEpochFromZoom(epoch, to, res);
    }

    componentDidUpdate(prevProps, prevState) {
        if ( prevProps.ipDetails != this.props.ipDetails || prevProps.selectedIP != this.props.selectedIP ) {
            this.loadGraph(this.props, this.props.selectedIP, this.props.ipDetails.endPoint);
        }
        if (Utils.isSet(this.props.generateURL)) {
            this.props.generateURL();
        }
    }

    componentDidMount(){
        if (Utils.isSet(this.props.selectedIP)) {
            this.loadGraph(this.props, this.props.selectedIP, this.props.ipDetails.endPoint);
        }
    }

    render() {
        let {ip, alias} = this.getDataInfo();
        const themeLabel = Utils.getPropertyOfObject(['theme', 'label'], this.props, '');
        return (
            <div className={"graph-wrap " + (this.props.dashView ? 'dash-view': '')} id={this.getID()}>
                <Header
                    ipDetails={this.props.ipDetails}
                    selectedIP={ip}
                    ipName={alias}
                    setEpochWithEndpoint={this.props.setEpochWithEndpoint}
                    showInfo={this.props.showInfo}
                    isIframe={this.props.isIframe}
                    chart={this.state.chart}
                    switchYAxis={this.switchYAxis}
                    isMobile={this.props.isMobile}
                    themeLabel={themeLabel}
                    limitedView={this.props.limitedView}
                    dashView={this.props.dashView}
                />
                <div className="graph-inner-wrap">
                    <InnerWrap
                        ipDetails={this.props.ipDetails}
                        setGraphType={this.props.setGraphType}
                        setAccuracyType={this.props.setAccuracyType}
                        setZoom={val => this.setChartInstance('zoomLevel', val)}
                        zoomLevel={this.state.zoomLevel}
                        ipName={alias}
                        selectedIP={ip}
                        setUTC={this.setUTC}
                        isUTC={this.state.isUTC}
                        isMobile={this.props.isMobile}
                        limitedView={this.props.limitedView}
                    />
                    <Graph graphData={this.state.graphData}
                           originalData={this.state.originalData}
                           graphType={this.props.ipDetails.graphType}
                           ipDetails={this.props.ipDetails}
                           selectedIP={this.props.selectedIP}
                           setChartInstance={this.setChartInstance}
                           setEpochFromZoom={this.setEpochFromZoom}
                           isMobile={this.props.isMobile}
                           theme={this.props.theme}
                           isUTC={this.state.isUTC}
                           stats={this.state.stats}
                           zoomLevel={this.state.zoomLevel}
                           chartWidth={Utils.getChartLineWidth(this.props.isMobile)}
                    />
                    <GraphFooter
                        {...this.props}
                        ipDetails={this.props.ipDetails}
                        ipName={alias}
                        ip={ip}
                        chart={this.state.chart}
                        graphData={this.state.graphData}
                        stats={this.state.stats}
                        pxWidth={this.state.width}
                    />
                </div>
                {this.props.showInfo && !this.props.isIframe &&
                    <TraceLegend
                        trueTime={this.getTrueTimeFrame()}
                        chart={this.state.chart}
                        ipDetails={this.props.ipDetails}
                        selectedIP={this.props.selectedIP}
                        isMobile={this.props.isMobile}
                    />
                }
            </div>
        );
    }
};

export default GraphContainer;
