/**
 * Created by Matt on 5/23/16.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import crossroads from 'crossroads';
import hasher from 'hasher';
import 'signals';

import Main from '../react-js/main.jsx';
import Error from '../react-js/error/404error.jsx';
import Utils from './utils.js';

var printRoutes = false;
const Route = {
    init(){
        var defaultColor = "161F29";
        Route.isMobile = (window.innerWidth <= 800 && window.innerHeight <= 600);
        const crossroadInstance = crossroads.create();
        crossroadInstance.greedyEnabled = false;
        const container = document.getElementById('routeContent');

        // example
        // ["ae19....1", "basic", "15110", "1590469598", "AMS", "&info=noinfo&iframe=yes"]
        const regex0 = /^ip=([^&]+)&type=(basic|advanced)&epoch=(\d+)&end=(\d+)&slave=([^&\r\n]+)(.*)$/; // [ip-0,type-1,epoch-2,end-3,slave-4,params-5]
        const regex1 = /^ip=([^&]+)&type=(basic|advanced)&epoch=(\d+)&slave=([^&\r\n]+)(.*)$/; // [ip-0,type-1,epoch-2,slave-3,params-4]

        const segregateParams = (strParams) => {
            const out = {};
            const paramKeys = ['info', 'iframe', 'theme'];
            paramKeys.forEach( (key) => {
                const regex = new RegExp('&'+key+'=([^&]+)'); // matches e.g. &info=noinfo or &iframe=yes ...
                out[key] = Utils.getPropertyOfObject([1], strParams.match(regex), '');
            })
            return out;
        };

        const setFixedParams = (strParams, withEndpoint=false) => {
            const out = {};
            let paramKeys = ['ip', 'graphType', 'epoch', 'probe'];
            if(withEndpoint) {paramKeys.splice(3,0,'endPoint');}
            paramKeys.forEach((key,i) => {
                out[key] = strParams[i];
            });
            return out;
        };

        let routeReg0 = crossroadInstance.addRoute ( regex0,
            (...urlParams) => {
                const { ip, graphType, epoch, endPoint, probe } = setFixedParams(urlParams, true);
                const optionalParams = Utils.getPropertyOfObject([5], urlParams, '');
                const { info, iframe, theme} = segregateParams(optionalParams);
                const graph = { endPoint, epoch, graphType, probe: 'slave='+probe.toUpperCase() };
                ReactDOM.render(
                    <Main
                        selectedIP={ip}
                        graph={graph}
                        color={defaultColor}
                        isMobile={ Route.isMobile }
                        isIframe={ iframe === 'yes'}
                        showInfo={ info !== 'noinfo' }
                        theme={theme}
                        limitedView={ iframe === 'yes' && info === 'noinfo'}
                    />, container );
                crossroadInstance.resetState();
            }
        );
        routeReg0.matched.add(() => { if (printRoutes) console.log('#RR0'); });

        let routeReg1 = crossroadInstance.addRoute ( regex1,
            (...urlParams) => {
                const { ip, graphType, epoch, probe } = setFixedParams(urlParams);
                const optionalParams = Utils.getPropertyOfObject([4], urlParams, '');
                const { info, iframe, theme} = segregateParams(optionalParams);
                const graph = { epoch, graphType, probe: 'slave='+probe.toUpperCase() };
                ReactDOM.render(
                    <Main
                        selectedIP={ip}
                        graph={graph}
                        color={defaultColor}
                        isMobile={ Route.isMobile }
                        isIframe={ iframe === 'yes'}
                        showInfo={ info !== 'noinfo' }
                        theme={theme}
                        limitedView={ iframe === 'yes' && info === 'noinfo'}
                    />, container );
                crossroadInstance.resetState();
            }
        );
        routeReg1.matched.add(() => { if (printRoutes) console.log('#RR1'); });

        let route6 =  crossroadInstance.addRoute (
            'integration/{ip}/{slave}/{epoch}',
            (ip, slave, epoch) => {
                const probe = 'slave='+slave.toUpperCase();
                const graph = { epoch, probe, graphType:'basic' };
                ReactDOM.render(
                    <Main
                        selectedIP={ip}
                        graph={graph}
                        color={defaultColor}
                        isMobile={false}
                        isIframe={true}
                        showInfo={false}
                        limitedView={true}
                        dashView={true}
                    />, container);
                crossroadInstance.resetState();
            });
        route6.matched.add(() => { if (printRoutes) console.log('#6'); });


        // KEEEP !!! - legacy route
        let route4 = crossroadInstance.addRoute (
            '{ip}/{graphType}/{epoch}/{slave}/{info}',
            (ip, graphType, epoch, slave, info) => {
                const probe = 'slave='+slave.toUpperCase();
                const graph = { epoch, probe, graphType };
                ReactDOM.render(
                    <Main
                        selectedIP={ip}
                        graph={graph}
                        color={defaultColor}
                        isMobile={Route.isMobile}
                        isIframe={false}
                        showInfo={info === 'info'}
                    />, container);
                crossroadInstance.resetState();
            }
        );
        route4.matched.add(() => { if (printRoutes) console.log('#3'); });

        let routeDefault = crossroadInstance.addRoute(
            '',
            () => {
                ReactDOM.render( <Error />, container);
                crossroadInstance.resetState();
            }
        );
        routeDefault.matched.add(() => {if(printRoutes) { console.log('Route default');}});

        crossroadInstance.bypassed.add((request) => {
            if (printRoutes) { console.log('Bypassed route'); }
            ReactDOM.render(<Error />, container);
        });

        crossroadInstance.routed.add(() => { if (printRoutes) { console.log('Rout routed'); } });
        const parseHash = (newHash, oldHash) => { crossroadInstance.parse(newHash); };
        hasher.initialized.add(parseHash); // parse initial hash
        hasher.changed.add(parseHash); // parse hash changes
        hasher.init(); // start listening for history change
    },
};

export default Route;
