import React from 'react';
import styles from './header.module.css';
import EpochPicker from "../epochPicker.jsx";
import Utils from '../../../../js/utils';


const Header = (props) => {
    const { isMobile, showInfo, limitedView } = props
    // hidden epoch picker under following conditions
    const hideEpoch = limitedView || (isMobile && !showInfo);
    const isRegularView = !hideEpoch;
    const getEpochPicker = () => (
        <EpochPicker epoch={props.ipDetails.epoch}
                     setEpochWithEndpoint={props.setEpochWithEndpoint}
                     resetButton={props.resetButton}
                     resetZoom={props.resetZoom}
                     isMobile={props.isMobile}
        />
    );
    const getLogo = () => {
        if (Utils.isSet(props.dashView) && props.dashView){
            return;
        }
        const instanceName = window.location.host.split('-')[0];
        const logoName = 'cocopacket-logo' + (props.themeLabel === 'light' ? '_black.png' : '_inverse.svg');
        return (
            <div className={styles.logoWrap}>
                <a className={styles.logo}
                   href='http://cocopacket.com'
                   rel="noreferrer noopener"
                   target="_blank"
                >
                <img className={styles.img + ' company-logo'}
                     alt={"logo_" + props.themeLabel}
                     key={"logo_" + props.themeLabel}
                     src={"/custom/" + instanceName + "/logo.svg"}
                     onError={(e) => e.target.src = '/less/img/' + logoName}
                />
                </a>
            </div>
        );
    }

    return (
        <div className={`header ${styles.wrap} ${(isMobile && !limitedView ? styles.mobile : '')} ${(limitedView ? styles.limited : '')}`}>
            {!hideEpoch && getEpochPicker()}
            {getLogo()}
        </div>
    );
}

export default Header;
