import React from 'react';
import * as Sentry from '@sentry/browser';
import MainPanelDetail from './mainPanel/mainPanel.detail.jsx';
import DefaultError from './error/defaultError.jsx';
import {Helmet} from "react-helmet";
import Utils from '../js/utils.js';


class Main extends React.Component {
    constructor(props){
        super(props);
        this.state = this.getInitialState();
    }
    getInitialState = () => ({ theme: this.getTheme(this.props), graphType: this.props.graphTypeInitial, hasError: false, })

    getTheme = ({theme}) => {
        if(theme === 'light') {
            return this.getLightTheme();
        }
        return this.getDarkTheme();
    }

    getLightTheme() {
        return {
            label: 'light',
            colorBckgr: '#FFFFFF',
            colorText: '#C9CBD6',
            shadowReverse: true,
            sideText: '#5A5D70',
            simpleLine: 'rgba(0,0,0,0.5)',
        };
    }

    getDarkTheme() {
        return {
            label: 'dark',
            colorBckgr: '#3F4155',
            colorText: '#474C60',
            shadowReverse: false,
            sideText: '#9496A6',
            simpleLine: 'rgba(200,200,200,0.6)',
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.theme != nextProps.theme){
            this.setState({theme: this.getTheme(nextProps)});
        }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true });
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    render(){
        if (this.state.hasError) {
            return (<DefaultError isMobile={this.props.isMobile} />);
        }
        let classes = [];
        if(this.props.isMobile) { classes.push('mobile'); }
        if(this.props.theme === 'light') { classes.push('theme-light'); }
        classes.push('custom-theme');
        return (
            <div id='mother' className={classes.join(' ')}>
                <MainPanelDetail
                    { ...this.props }
                    theme={this.state.theme}
                />
            </div>
        )
    }
};

export default Main;