import React from 'react';
import Clipboard from 'clipboard';
import styles from './inner.module.css';

class InnerGraphHeaderMobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            graphType: this.props.ipDetails.graphType,
            accuracy: this.props.ipDetails.accuracy,
        };
    }

    onChangeHandlerGraphType = () => {
        const type = this.state.graphType == 'basic' ? 'advanced' : 'basic';
        this.changeGraphType(type);
    }

    changeGraphType = (graphType) => {
        this.setState({ graphType });
        this.props.setGraphType(graphType);
    }

    getSwitches(){
        const isSmoke = this.state.graphType == 'advanced';
        return (
            <div className='switches'>
                <div className='switch'>
                    <span className={isSmoke ? '' : 'active'} onClick={() => this.changeGraphType('basic')}>
                        Basic
                    </span>
                    <label className='switch-slider'>
                        <input type='checkbox' onChange={this.onChangeHandlerGraphType} checked={isSmoke} />
                        <span className='slider round' />
                    </label>
                    <span className={isSmoke ? 'active' : ''} onClick={() => this.changeGraphType('advanced')}>
                        Smoke
                    </span>
                </div>
            </div>
        );
    }

    getTitle() {
        const probe = this.props.ipDetails.probe.split('=')[1];
        return (
            <div className='title-wrap'>
                <span className='title'>
                    <a onClick={this.copyIPAction}>
                        <span ref='ip-title' data-clipboard-text={this.props.ipName} className='ip ip-info copy-btn'>
                            {this.props.ipName + ' - ' + probe}
                        </span>
                    </a>
                </span>
            </div>
        );
    }

    copyIPAction(e){
        let scope = this;
        let selector = $(this.refs['ip-title']);
        let color = selector.css('color');
        selector.css('color', '#ADC852');
        selector.text('COPIED');
        setTimeout(function(){
            selector.text(scope.props.ipName);
            selector.css('color', color);
        },2000)
    }

    componentDidUpdate(){
        var clipboard = new Clipboard('.copy-btn');
    }

    render(){
        const { isMobile, limitedView } = this.props;
        const styleKey = (isMobile && !limitedView) ? 'mobile' : 'limited';
        return (
            <div className={styles.wrap + ' panel ' + styles[`wrap--${styleKey}`]}>
                {this.getTitle()}
                {this.getSwitches()}
            </div>
        );
    }
};

export default InnerGraphHeaderMobile;
